// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-songs-blood-of-the-machine-js": () => import("./../src/pages/songs/blood-of-the-machine.js" /* webpackChunkName: "component---src-pages-songs-blood-of-the-machine-js" */),
  "component---src-pages-songs-fisting-the-reaper-js": () => import("./../src/pages/songs/fisting-the-reaper.js" /* webpackChunkName: "component---src-pages-songs-fisting-the-reaper-js" */),
  "component---src-pages-songs-flashes-from-the-sky-js": () => import("./../src/pages/songs/flashes-from-the-sky.js" /* webpackChunkName: "component---src-pages-songs-flashes-from-the-sky-js" */),
  "component---src-pages-songs-fury-of-the-undead-js": () => import("./../src/pages/songs/fury-of-the-undead.js" /* webpackChunkName: "component---src-pages-songs-fury-of-the-undead-js" */),
  "component---src-pages-songs-hail-to-mario-js": () => import("./../src/pages/songs/hail-to-mario.js" /* webpackChunkName: "component---src-pages-songs-hail-to-mario-js" */),
  "component---src-pages-songs-index-js": () => import("./../src/pages/songs/index.js" /* webpackChunkName: "component---src-pages-songs-index-js" */),
  "component---src-pages-songs-taking-your-life-tonight-js": () => import("./../src/pages/songs/taking-your-life-tonight.js" /* webpackChunkName: "component---src-pages-songs-taking-your-life-tonight-js" */)
}

